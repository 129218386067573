import { NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { BlockStack, Card, IndexTable, Page } from '@shopify/polaris';
import { MenuGroupDescriptor } from "@shopify/polaris/types";
import { useI18n } from "@shopify/polaris/utilities/i18n";
import { TABLE_NAMES, isTable, ok } from "common";
import { DataService } from "data-service";
import pluralize from "pluralize";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { useAngular, useLoadingMarkup, useObserver, useRefresh } from "react-utils";
import { TableListInner, useTableListInner } from "../tables/TableListInner";
import { getTableViews } from "../tables/table-views";
import { ColumnBase, FormsQuestionService, KeyColumnBase, LedgerTables, showPageEditModal, UIService, ValueColumnBase } from '../utils';
import { QuestionForm } from "../components/QuestionForm";
import { Generator } from "../utils/Generator";
import { SelectTable } from "../tables/SelectTable";


export const centsToDollars = (cents: string) => `${+(cents + "e-2")}`;
export const dollarsToCents = (dollars: string) => `${+(dollars + "e+2")}`;


export function ListTablePage() {
  const { table } = useParams();
  ok(isTable(table), "Invalid table: " + table);
  const { get } = useAngular();
  const fq = get(FormsQuestionService);
  const ui = get(UIService);
  const data = get(DataService);
  const router = get(Router);
  const zone = get(NgZone);
  const actionGroups: MenuGroupDescriptor[] = [];

  const i18n = useI18n();

  const [counter, setCounter] = useState(0);

  useObserver(useRefresh(), () => { setCounter(count => count + 1); });

  const views = useMemo(() => getTableViews(table) ?? [], [table]);
  const title = pluralize(table);


  const onSelectRow = async (id: string | undefined) => {
    console.log("onSelectRow", table, id);
    if (table === "BranchUser" || table === "User") {
      if (!id) {
        const dialog = await fq.onClickFindUser();
        if (dialog) dialog.onSaveSuccess.subscribe(async (e) => {
          dialog.subs.unsubscribe();
          if (e.table === "User" && e.id) {
            await fq.onClickEditUser(e.id);
          }
        });
      } else {
        await fq.onClickEditUser(id);
      }
    } else {
      if (id && table.inArray(["Item", "Promotion", "Unit", "UnitType"])) {
        await showEditTableDialog(ui, table, id);
      } else if (id) {
        fq.onClickEvent({ action: "edit", table, id })
      } else {
        fq.onClickEvent({ action: "add", table, params: {} })
      }
    }
  };


  const primaryAction = [...LedgerTables, "Rental", "UserPermission"].contains(table) ? null : {
    content: 'Add ' + table, onAction: () => { onSelectRow(undefined); }, disabled: false,
  };

  const loadingMarkup = useLoadingMarkup(pluralize(table).toLocaleLowerCase());



  return (
    <Page fullWidth title={title} primaryAction={primaryAction} actionGroups={actionGroups}>
      <Card padding="0">
        <ListTablePageInner
          key={table + counter}
          table={table}
          views={views}
          loadingMarkup={loadingMarkup}
          onSelectRow={onSelectRow}
          pagination
        />
      </Card>
    </Page>
  );
}

function useGroupRows() {
  return (notHiddenRows: any[], notHiddenCols: readonly ColumnBase[], idcol: ColumnBase) => {
    const groups = new Map<string, any[]>();
    console.log(notHiddenCols);
    notHiddenRows.forEach(row => {
      const date = notHiddenCols[0].get(row);
      const key = date.slice(0, 7);
      if (!groups.has(key)) groups.set(key, []);
      groups.get(key)!.push(row);
    });
    const rows = [...groups.keys()].map(key => {
      const row = {};
      notHiddenCols.forEach(col => {
        if (!(col instanceof KeyColumnBase)) throw new Error("Expected KeyColumnBase");
        if (col.key === "line/Date") col.set(row, key + "-01");
        if (col.filterType === "currency")
          return col.set(row, groups.get(key)!.map(e => col.agg(e)).reduce((n, e) => n + e, 0));
        return "---";
      });

      return row;
    });
    console.log(rows);
    return rows;
  };
}
function ListTablePageInner(opts: Parameters<typeof TableListInner>[0]) {

  const state = useTableListInner(opts.table === "CentralLedger" ? {
    ...opts,
    rowsMapper: useGroupRows(),
    onSelectRow: () => { },
  } : opts);

  return (
    <BlockStack>
      {state.useMarkup()}
    </BlockStack>
  );
}
async function showEditTableDialog(ui: UIService, table: TABLE_NAMES, id: string) {
  return await showPageEditModal({
    table,
    id,
    group: (mode) => {
      const schema = ui.schema as any;
      if (schema[table + mode]) return schema[table + mode](mode);
      if (schema[table]) return schema[table](mode);
      return Generator.Group(table, mode, ui);
    },
    useTitle: (page) => {
      return pluralize(table);
    },
    useWhenLoaded: (page, onClose) => {
      return <QuestionForm group={page.group} mode={page.mode} />;
    },
  })
}
